import React, { useEffect } from "react"

import Layout from "@components/layout/rootLayout.js"
import Download from "@components/download"
import linker from "@src/common/linker"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"

const Default = ({ pageContext: { locale } }) => {
  useEffect(() => {
    trackCustomEvent({
      category: "ecommerce",
      action: "download_3",
    })
  }, [])

  linker.setUrl(
    "https://storage.googleapis.com/arcpanel-web.appspot.com/ARCPanel3.ccx?GoogleAccessId=firebase-adminsdk-o0cmh%40arcpanel-web.iam.gserviceaccount.com&Expires=16730323200&Signature=rZoPW1E8wOsf5lewaPBs5TNCn3kadZWapfOSm7ZoGU5ONBtSsZ%2B4Idp%2BBWsWvH3MCNjwP7yyY9U0EfKQnQom1EZwOEgCeipcGG0rzsQZy83eN9Mi6qWskUqti3b2B2FUztRTb%2F3RACnwOYhs56ROG1H68ry2tsUMCNv5bjTDEB2s6BXzBXSplybypx1H2qA%2Fu%2BUmGlRZxD4SZsR3WDMrEFrY90KIeNxfta5DkBTgws90%2BCmednwadXFaoHh52BKkiBjy1wwWOkcX9lEIoxUh6hbCfEdZGGYTYv0UmY3ZaM4ToRw83EiYx3MZBvaTAz4Hqq6qmvC5zhGjL39WqkDcXQ%3D%3D"
  )

  return (
    <Layout locale={locale}>
      <Download />
    </Layout>
  )
}

export default Default

