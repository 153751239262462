import React from "react"

import { H2, PM } from "@src/style/core"
import { StyledMessage } from "@components/common/styledMessage"
import { CenterColumn } from "@src/style/globalStyle"

export default () => {
  return (
    <CenterColumn>
      <H2>
        <StyledMessage id="download.thanks" />
      </H2>
      <PM>
        <StyledMessage id="download.v3.install" />
      </PM>
      <PM>
        <StyledMessage id="download.v2.install" />
      </PM>
    </CenterColumn>
  )
}
